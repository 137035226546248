import "./CropEditing.css";
import React, {useCallback, useMemo, useState, useEffect, use} from "react";
import { FiEdit3, FiSave } from "react-icons/fi";
import {deconstructPlaybackUrl} from "../../../../../utility/Utilities";
import {useVideoPlayer} from "../../../../../components/VideoPlayer/VideoPlayerProvider";
import VideoOverlay from "../../../../../components/VideoPlayer/VideoOverlay/VideoOverlay";
import {secToFrame, useFieldVisionData} from "../../../../../utility/useFieldVisionData";
import {VisualizePOI} from "../../../../../components/VideoPlayer/VideoOverlay/VisualizePOI";
import Config from "../../../../../utility/Config";
import savitzkyGolay from 'ml-savitzky-golay';
import EditingTimelineNumber from "../EditingTimelineNumber";

function PoiOverlays ({
    editsMap,
    interpolatedMap,
    isEditing,
    aspectRatio,
    assetId,
    assetTimeOffsetMs,
    fps=Config.expectedFrameRate,
    graphType=[]
}) { 

    const [override, setOverride] = useState(null)
    const [editingPoi, setEditingPoi] = useState(false)
    const [calculatePoi, setCalculatePoi] = useState(false)

    const {getCurrentTime, playerRef} = useVideoPlayer()
    
    // Update the frame number ref each render
    const frameNumber = secToFrame(getCurrentTime() + (assetTimeOffsetMs / 1000), fps)
    const originalPoiMap = useFieldVisionData("poi", frameNumber, getCurrentTime, assetId, assetTimeOffsetMs, fps, true)
    const originalPoi = useFieldVisionData("poi", frameNumber, getCurrentTime, assetId, assetTimeOffsetMs, fps)
    
    const overriddenMousePoi = editsMap?.get(frameNumber);
    const overriddenSmoothPoi = interpolatedMap?.get(frameNumber);
    const poiXMouse = override || overriddenMousePoi?.x || originalPoi.x
    const poiXSmooth = overriddenSmoothPoi?.x || originalPoi.x

    const onDrag = useCallback((frame, x, y) => {
        if (!editingPoi) setEditingPoi(true)
        setOverride(x)
        editsMap.set(frame, {id: originalPoi.id, x:x, y:y});

        const currentTime = playerRef.current.currentTime()
        const frameNow = secToFrame(currentTime + (assetTimeOffsetMs / 1000), fps);

        // console.log(
        //     "current time: " + currentTime, 
        //     "frame now: " + frameNow, 
        //     "frame: " + frame, 
        //     // "un-rounded frame: " + (playerRef.current.currentTime() + (assetTimeOffsetMs / 1000)) * fps
        // )
    }, [setOverride])
    
    // useEffect(() => {
    //     if (!isEditing) return
    //     const onTimeUpdate = () => {
    //         const currentTime = playerRef.current.currentTime()
    //         const frame = secToFrame(currentTime + (assetTimeOffsetMs / 1000), fps);
    //         console.log(
    //             "current time: " + currentTime, 
    //             "frame: " + Math.floor(playerRef.current.currentTime() + (assetTimeOffsetMs / 1000)) * fps, 
    //             // "un-rounded frame: " + (playerRef.current.currentTime() + (assetTimeOffsetMs / 1000)) * fps
    //         )
    //     }
    //     const interval = setInterval(onTimeUpdate, 10)
    //     return () => clearInterval(interval)
    // }, [isEditing])

    const interpolatePoi = () => {
        const allKeys = editsMap && Array.from(editsMap.entries()).map(([key, value]) => key)
        const allEdits = editsMap && Array.from(editsMap.entries()).map(([key, value]) => value)
        const editsX = editsMap && Array.from(editsMap.entries()).map(([key, value]) => value.x)
        
        let options = {
            derivative: 0, 
            windowSize: 25, 
            polynomial: 1, 
            pad: "pre",
        }
        const interpolated = savitzkyGolay(editsX, 1, options)

        for (let index = 0; index < allKeys.length; index++) {
            interpolatedMap.set(allKeys[index], {id: allEdits[index].id, x: interpolated[index], y: allEdits[index].y})
        }
    }

    const onDragEnd = () => {
        setCalculatePoi(true)
        setEditingPoi(false)
    }

    useEffect(() => {
        if (editingPoi) playerRef.current.playbackRate(0.5)
        else playerRef.current.playbackRate(1)
    }, [editingPoi])

    useEffect(() => {
        if (calculatePoi && editsMap.size !== 0) {
            for (let index = 1; index < 20; index++) {
                const lastEntry = Array.from(editsMap.entries()).pop()
                const lastEditedFrame = lastEntry[0]
                const lastEdited = lastEntry[1]
                const lastOriginalPoi = originalPoiMap.get(lastEditedFrame)
                if (!lastEdited || !lastOriginalPoi) {
                    break
                }
                if (lastEdited.x === lastOriginalPoi.x) {
                    break
                }
                if (Math.abs(lastEdited.x - lastOriginalPoi.x) < 0.05) {
                    break
                }
                if ((lastEdited.x - lastOriginalPoi.x) > 0.05) {
                    editsMap.set(lastEditedFrame + 1, {id: lastOriginalPoi.id, x: lastEdited.x - 0.05, y: lastEdited.y})
                }
                if ((lastEdited.x - lastOriginalPoi.x) < -0.05) {
                    editsMap.set(lastEditedFrame + 1, {id: lastOriginalPoi.id, x: lastEdited.x + 0.05, y: lastEdited.y})
                }        
            }
            interpolatePoi()
            setCalculatePoi(false)
            setOverride(null)
        }
    }, [calculatePoi, editsMap])

    return (
        <>
            {/* Show the original POI */}
            {graphType.includes("original") && (
                <VisualizePOI
                    x={originalPoi.x}
                    y={originalPoi.y}
                    rectangle={true}
                    dimming={!isEditing}
                    rectangleColor={isEditing ? "#ff0059" : "#9b9b9b"}
                    cross={true}
                    aspectRatio={aspectRatio}
                />
            )}
            {(isEditing && graphType.includes("mouse")) && (
                <VisualizePOI
                    x={poiXMouse}
                    y={0}
                    assetTimeOffsetMs={assetTimeOffsetMs}
                    onDrag={onDrag}
                    onDragEnd={onDragEnd}
                    dimming={true}
                    rectangleColor={"#ffd100"}
                    rectangle={true}
                    cross={false}
                    aspectRatio={aspectRatio}
                />
            )}
            {(isEditing && graphType.includes("smooth") && interpolatedMap.size !== 0) && (
                <VisualizePOI
                    x={poiXSmooth}
                    y={0}
                    dimming={true}
                    rectangleColor={"#2DC30A"}
                    rectangle={true}
                    cross={true}
                    aspectRatio={aspectRatio}
                />
            )}
        </>
    )
}

function CropEditingGraph ({
    editsMap, 
    interpolatedMap,
    assetId, 
    assetTimeOffsetMs, 
    fps=Config.expectedFrameRate,
    graphType=null,
    selectGraph,
    isEditing,
    timelineStart,
    start,
    end,
}) {

    const {getCurrentTime} = useVideoPlayer()

    const currentTime = useMemo(() => {return getCurrentTime()}, [])
    const currentFrame = useMemo(() => {return secToFrame(currentTime + (assetTimeOffsetMs / 1000), fps)}, [])

    // keep fetching the current time, to be used for showing the clip position pin in the POI graph
    const current = getCurrentTime()
    const currentFrameActive = useMemo(() => {return secToFrame(current + (assetTimeOffsetMs / 1000), fps)}, [current])
    
    // Start and end of the whole export timeline bar
    const startTime = (currentTime + 45) - 450
    const endTime = (currentTime + 45) + 450
    const timelineStartFrame = secToFrame(startTime + (assetTimeOffsetMs / 1000), fps)
    const timelineEndFrame = secToFrame(endTime + (assetTimeOffsetMs / 1000), fps)

    // Selected start and end to be exported (blue bar in the export timeline)
    const selectedStartFrame = useMemo(() => {return secToFrame((timelineStart + start) + (assetTimeOffsetMs / 1000), fps)}, [start])
    const selectedEndFrame = useMemo(() => {return secToFrame((timelineStart + end) + (assetTimeOffsetMs / 1000), fps)}, [end])

    // The POI of the whole export timeline bar (selected export duration and padding)
    const originalPoiMap = useFieldVisionData("poi", currentFrame, getCurrentTime, assetId, assetTimeOffsetMs, fps, true, timelineStartFrame, timelineEndFrame)
    
    // For position and distance of every single "x" in the graph (css styling)
    const frameSize = selectedEndFrame - selectedStartFrame
    const frameSpace = (100 / frameSize).toFixed(7)

    // Frames to be used for the POI graph (original, mouse-overridden, interpolated/smoothed)
    const selectedDurationFrames = new Map()
    const mouseOverriddenFrames = new Map()
    const interpolatedFrames = new Map()
    
    // Updating the frames according to the selected start and selected end (blue bar)
    // Showing all the original POI from the selected start to the selected end
    for (let key = selectedStartFrame; key <= selectedEndFrame; key++) {
        selectedDurationFrames.set(key, originalPoiMap.get(key))
    }

    // Updating the map that stores mouse overridden/dragged POI
    if (editsMap.size !== 0) {
        // Loop through the frames from selected start to the end
        for (let key = selectedStartFrame; key <= selectedEndFrame; key++) {
            // Only registered/edited frames have the POI, shown in the graph
            if (editsMap.has(key)) {
                mouseOverriddenFrames.set(key, editsMap.get(key))
            // unregistered/unedited frames don't have POI, won't be shown in the graph
            } else {
                mouseOverriddenFrames.set(key, {id: key, x: null, y: null})
            }
        }
    }

    // Updating the map that stores interpolated POI
    if (interpolatedMap.size !== 0) {
        // Loop through the frames from selected start to the end
        for (let key = selectedStartFrame; key <= selectedEndFrame; key++) {
            // Only registered/edited frames have the POI, shown in the graph
            if (interpolatedMap.has(key)) {
                interpolatedFrames.set(key, interpolatedMap.get(key))
            // unregistered/unedited frames don't have POI, won't be shown in the graph
            } else {
                interpolatedFrames.set(key, {id: key, x: null, y: null})
            }
        }
    }

    const renderOriFrames = useMemo(() => {
        if (selectedDurationFrames.size === 0) return null
        return Array.from(selectedDurationFrames.entries()).map(([key, value], idx) => {
            const style = {
                left: (frameSpace * (idx+1)) + "%",
                top: ((value?.x || 0.5) * 100) + "%"
            }
            return <div key={key} style={style} className="poi-graph-dot"></div>;
        });
    }, [isEditing, selectedDurationFrames, selectedStartFrame, selectedEndFrame])

    const renderEditedFrames = useMemo(() => {
        if (mouseOverriddenFrames.size === 0) return null
        return Array.from(mouseOverriddenFrames.entries()).map(([key, value], idx) => {
            if (!value.x) return null
            else {
                const style = {
                    left: (frameSpace * (idx+1)) + "%",
                    top: (value?.x * 100) + "%"
                }
                return <div key={key} style={style} className="poi-graph-dot yellow"></div>;
            }
        })
    }, [isEditing, mouseOverriddenFrames, selectedStartFrame, selectedEndFrame])

    const renderInterpolatedFrames = useMemo(() => {
        if (interpolatedFrames.size === 0) return null
        return Array.from(interpolatedFrames.entries()).map(([key, value], idx) => {
            if (!value.x) return null
            else {
                const style = {
                    left: (frameSpace * (idx+1)) + "%",
                    top: (value?.x * 100) + "%"
                }
                return <div key={key} style={style} className="poi-graph-dot green"></div>;
            }
        })
    }, [isEditing, interpolatedFrames, selectedStartFrame, selectedEndFrame])

    const indicatorStyle = {left: (frameSpace * ((currentFrameActive - selectedStartFrame)+1)) + "%"}
    
    const loadingIndicator = (
        <div className="loading-graph-indicator">
            Loading
            <span className="loading-dot">.</span>
            <span className="loading-dot">.</span>
            <span className="loading-dot">.</span>
        </div>
    )

    const graphLinesAndIndicator = (
        <>
            <div style={indicatorStyle} className="poi-graph-indicator"></div>
            {graphType.includes("original") && renderOriFrames}
            {graphType.includes("mouse") && renderEditedFrames}
            {graphType.includes("smooth") && renderInterpolatedFrames}
        </>
    )

    return (
        <>
            <div className="poi-graph-cont">
                {originalPoiMap.size === 0 ? loadingIndicator : graphLinesAndIndicator}
            </div>
            <EditingTimelineNumber 
                timelineStart={Math.floor(timelineStart + start)} 
                timelineEnd={Math.floor(timelineStart + end)}
                upsideDown
                />
            <br />
            <div className="graph-type-btns">
                <div className="graph-type-btn">
                    <input 
                        type="checkbox"
                        checked={graphType.includes("original")}
                        onChange={() => selectGraph("original")}
                        />
                    <div>Original</div>
                </div>
                <div className="graph-type-btn">
                    <input 
                        type="checkbox"
                        checked={graphType.includes("mouse")}
                        onChange={() => selectGraph("mouse")}
                        />
                    <div>Mouse (yellow)</div>
                </div>
                <div className="graph-type-btn">
                    <input 
                        type="checkbox"
                        className="graph-type-btn-input"
                        checked={graphType.includes("smooth")}
                        onChange={() => selectGraph("smooth")}
                        />
                    <div>Smooth (green) - only shown after done dragging the poi box</div>
                </div>
            </div>
        </>
    )
}

function KeyboardShortcutHandler () {

    const {playerRef} = useVideoPlayer()

    const onKeyPress = (e) => {
        const currentTime = playerRef.current?.currentTime()
        // "F" key
        if (e.keyCode === 82) playerRef.current?.currentTime(Math.max(0, currentTime - 2))
        // "R" key
        if (e.keyCode === 70) playerRef.current?.currentTime(Math.max(0, currentTime + 2))
    }

    useEffect(() => {
        window.addEventListener("keydown", onKeyPress);
        return () => window.removeEventListener("keydown", onKeyPress);
    })
    
    return (
        <div className="keyboard-shortcuts-cont">
            <div>Keyboard shortcuts</div>
            <div>R = Rewind 2 seconds</div>
            <div>F = Fast forward 2 seconds</div>
        </div>
    )
}

export default function CropEditing ({
    timelineStart,
    start,
    end,
}) {
    
    const {playbackUrl} = useVideoPlayer()
    const [aspectRatio, setAspectRatio] = useState(null)
    const [isEditing, setIsEditing] = useState(false)
    let [assetId, assetTimeOffsetMs] = deconstructPlaybackUrl(playbackUrl)

    const [graphType, setGraphType] = useState(["original", "mouse", "smooth"])
    
    // Map of frames that have been edited with a new point of interest
    const editsMap = useMemo(
        () => isEditing ? new Map() : null,
        [assetId, assetTimeOffsetMs, isEditing])

    const interpolatedMap = useMemo(
        () => isEditing ? new Map() : null,
        [assetId, assetTimeOffsetMs, isEditing])

    // Not always loaded on initial render
    if (!assetId) return

    function onSave () {
        // TODO process editMap and push to server?

        setIsEditing(false)
    }

    const selectGraph = (type) => {
        if (graphType.includes(type)) {
            const updatedTypes = graphType.filter((t) => t !== type)
            setGraphType(updatedTypes)
        } else {
            setGraphType([...graphType, type])
        }
    }
    
    return (
        <>
            <div className="crop-editing-cont">
                <div>
                    Preview Auto-Crop:
                </div>
                <button type="button"
                        disabled={aspectRatio === null}
                        onClick={() => setAspectRatio(null)}>
                    Off
                </button>
                <button type="button"
                        disabled={aspectRatio === "1/1"}
                        onClick={() => setAspectRatio("1/1")}>
                    1:1
                </button>
                <button type="button"
                        disabled={aspectRatio === "9/16"}
                        onClick={() => setAspectRatio("9/16")}>
                    9:16
                </button>
                {(aspectRatio || isEditing) && (
                    <VideoOverlay className="poi-overlays-cont">
                        <PoiOverlays
                            editsMap={editsMap}
                            interpolatedMap={interpolatedMap}
                            isEditing={isEditing}
                            aspectRatio={aspectRatio || "9/16"}
                            assetId={assetId}
                            assetTimeOffsetMs={assetTimeOffsetMs} 
                            graphType={graphType}/>
                    </VideoOverlay>
                )}
                {isEditing && (
                    <div>
                        <button className="crop-editing-edit-btn"
                                type="button"
                                onClick={onSave}>
                            <FiSave /> Save
                        </button>
                        <button className="crop-editing-edit-btn"
                                type="button"
                                onClick={() => setIsEditing(false)}>
                            Cancel
                        </button>
                    </div>
                )}
                {!isEditing && (
                    <button className="crop-editing-edit-btn"
                            type="button"
                            onClick={() => setIsEditing(true)}>
                        <FiEdit3 /> Edit
                    </button>
                )}
            </div>
            {isEditing && (
                <CropEditingGraph
                    editsMap={editsMap}
                    interpolatedMap={interpolatedMap}
                    isEditing={isEditing}
                    aspectRatio={aspectRatio || "9/16"}
                    assetId={assetId}
                    assetTimeOffsetMs={assetTimeOffsetMs}
                    graphType={graphType}
                    selectGraph={selectGraph}
                    timelineStart={timelineStart}
                    start={start}
                    end={end}
                    />
            )}
            {isEditing && (
                <KeyboardShortcutHandler/>
            )}
        </>
    )
}