import allsvenskanLogo from "../img/allsvenskanLogo.png";
import superettanLogo from "../img/superettanLogo.png";
import fotbollPlayLogo from "../img/fotbollPlayLogo.svg";
// import norskTopFotballLogo from "../img/norskTopFotballLogo.svg";
import eliteserienLogo from "../img/eliteserienLogo.png";
import obosligaenLogo from "../img/obosligaenLogo.png";
import toppserienLogo from "../img/toppserienLogo.png";
import shlLogo from "../img/shlLogo.png";
import svenskafutsalliganLogo from "../img/svenskafutsalliganLogo.png";
import netherlandsFlag from "../img/netherlandsFlag.png";
import europeanLeaguesLogo from "../img/europeanLeaguesLogo.png";
import slovakiaFlag from "../img/slovakiaFlag.png";

// this hook is used by login screen and header (mapping the leagues)
export function useGetLeagueLogo (league) {

    let associationLogo = null
    let leagueLogo = null
    let displayName = ""

    // TODO get the logo using Config.association
    switch (league) {
        case "allsvenskan":
            associationLogo = fotbollPlayLogo
            leagueLogo = allsvenskanLogo
            displayName = "Fotboll Play"
            break
        case "superettan":
            associationLogo = fotbollPlayLogo
            leagueLogo = superettanLogo
            displayName = "Fotboll Play"
            break
        case "demo-tomas-l1":
            associationLogo = fotbollPlayLogo
            leagueLogo = allsvenskanLogo
            displayName = "Fotboll Play"
            break
        case "demo-tomas-l2":
            associationLogo = fotbollPlayLogo
            leagueLogo = superettanLogo
            displayName = "Fotboll Play"
            break
        case "demo-ragnar-l1":
            associationLogo = toppserienLogo
            leagueLogo = toppserienLogo
            displayName = "Toppserien"
            break
        case "eliteserien":
            associationLogo = null
            leagueLogo = eliteserienLogo
            displayName = "Norsk Top Fotball"
            break
        case "obosligaen":
            associationLogo = null
            leagueLogo = obosligaenLogo
            displayName = "Norsk Top Fotball"
            break
        case "toppserien":
            associationLogo = toppserienLogo
            leagueLogo = toppserienLogo
            displayName = "Toppserien"
            break
        case "svenskafutsalligan":
            associationLogo = svenskafutsalliganLogo
            leagueLogo = svenskafutsalliganLogo
            displayName = "Svenska Futsalligan"
            break
        case "shl":
            associationLogo = shlLogo
            leagueLogo = shlLogo
            displayName = "Svenska Hockeyligan"
            break
        case "demo-shl":
            associationLogo = shlLogo
            leagueLogo = shlLogo
            displayName = "Svenska Hockeyligan"
            break
        case "nld":
            associationLogo = europeanLeaguesLogo
            leagueLogo = netherlandsFlag
            displayName = "European Leagues"
            break
        case "svk":
            associationLogo = europeanLeaguesLogo
            leagueLogo = slovakiaFlag
            displayName = "European Leagues"
            break
        default:
            break 
    }

    return [associationLogo, leagueLogo, displayName]
}