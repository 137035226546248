import {useCallback, useEffect, useMemo, useState} from "react";
import {fetcher} from "./Backend";
import Config from "./Config";

export function secToFrame (seconds, fps) {
    return Math.floor(seconds * fps)
}

function getFieldVisionUrl (path, league, assetId, firstFrame, lastFrame, extra = "") {
    return `/fieldvision/v1/${path}/${league}/${assetId}?firstFrame=${firstFrame}&lastFrame=${lastFrame}${extra}`
}

function linear2D (first, firstWeight, second, secondWeight) {
    return {
        x: Math.min(1, first.x * firstWeight + second.x * secondWeight),
        y: Math.min(1, first.y * firstWeight + second.y * secondWeight)
    }
}

export function getInterpolatedFromMap (
    map,
    frameNumber,
    fallback = undefined,
    interpolationFn = undefined,
    distance = 6
) {

    if (map.has(frameNumber)) return map.get(frameNumber)

    let left = undefined
    let right = undefined
    for (let offset = 1; offset <= distance; ++offset) {
        if (map.has(frameNumber - offset)) {
            if (!interpolationFn) return map.get(frameNumber - offset)
            left = {offset: offset, value: map.get(frameNumber - offset)}
        }
        if (map.has(frameNumber + offset)) {
            if (!interpolationFn) return map.get(frameNumber + offset)
            right = {offset: offset, value: map.get(frameNumber + offset)}
        }
        if (left && right) break
    }
    if (left === undefined && right === undefined) return fallback
    if (left === undefined) return right.value
    if (right === undefined) return left.value
    const totalOffset = left.offset + right.offset
    return interpolationFn(left.value, 1 - left.offset / totalOffset, right.value, 1 - right.offset / totalOffset)
}

// Functions that take a map and a FV response body and populates the map
const fvResponseParsers = {
    "poi": (map, items) => {
        items.forEach(({frameNumber, id, x, y}) => {
            map.set(frameNumber, {id, x, y})
        })

    },
    "scenes": (map, items) => {
        items.forEach(({firstFrame, lastFrame, metadata}) => {
            const {cameraZoom, cameraZoomWarning} = metadata
            const msg = cameraZoom + (cameraZoomWarning ? ` (${cameraZoomWarning})` : "")
            for (let frame = firstFrame; frame <= lastFrame; frame++) {
                map.set(frame, msg)
            }
        })
    },
    "transitions": (map, items) => {
        items.forEach(({firstFrame, lastFrame, transitionType}) => {
            for (let frame = firstFrame; frame <= lastFrame; frame++) {
                map.set(frame, transitionType)
            }
        })
    },
    "audio/whistle": (map, items) => {
        items.forEach(({firstFrame, lastFrame}) => {
            for (let frame = firstFrame; frame <= lastFrame; frame++) {
                map.set(frame, true)
            }
        })
    },
    "audio/rms": (map, items) => {
        items.forEach(({firstFrame, lastFrame, rms}) => {
            for (let frame = firstFrame; frame <= lastFrame; frame++) {
                // In case there are rms for multiple tracks, just use the highest
                if (map.get(frame)) rms = Math.max(map.get(frame), rms)
                map.set(frame, rms)
            }
        })
    },
    "videoentities": (map, items) => {
        items.forEach(({id, videoEntityType, trackId, probability, frameNumber, x1, x2, y1, y2}) => {
            const arr = map.get(frameNumber) || []
            arr.push({id, x1, x2, y1, y2, type: videoEntityType, trackId, probability})
            map.set(frameNumber, arr)
        })
    },
}

export function useFieldVisionData (
    path, // Can be null when disabled, must be defined in fvResponseParsers
    frameNumber,
    getCurrentTime, // Function provided by useVideoPlayer
    assetId,
    assetTimeOffsetMs,
    fps,
    returnMap = false, // Indicate that the caller wants the map, not the current value
    start=undefined,
    end=undefined,
    extraQueryParams = "",
    chunkDuration = 90000,
) {
    // Note, we use this purely for triggering re-renders based on when map values change
    const [, setCurrentValue] = useState(undefined)
    const chunkStatus = useMemo(() => new Map(), [assetId, fps])
    const map = useMemo(() => new Map(), [assetId, fps])

    const onTimeUpdate = useCallback(() => {

        const calculateChunkRange = (timestamp) => {
            const start = Math.floor(timestamp / chunkDuration) * chunkDuration
            return [start, start + chunkDuration]
        }

        const fetchChunkData = async (startTimestamp, endTimestamp) => {
            const startFrame = start || secToFrame(startTimestamp / 1000, fps)
            const endFrame = end || secToFrame(endTimestamp / 1000, fps)
            try {
                const resp = await fetcher(getFieldVisionUrl(
                    path,
                    Config.database,
                    assetId,
                    startFrame,
                    endFrame,
                    extraQueryParams
                ))
                // Populate the map with the new items
                fvResponseParsers[path](map, resp)
                // We may have loaded the current frame's data, so we attempt to update
                setCurrentValue(map.get(secToFrame(getCurrentTime() + (assetTimeOffsetMs / 1000), fps)))
            } catch (error) {
                console.error("Error fetching chunk data:", error)
                // Optionally retry or log failure
            }
        }

        const currentTime = getCurrentTime() * 1000 + assetTimeOffsetMs
        // We try to load 2 chunks of data. We jump half a chunk back in time and make sure we have that one & next
        // This ensures that we always have at least half a chunk of data to either side
        const currentChunkCheck = Math.max(0, currentTime - Math.floor(chunkDuration / 2))
        const [currentChunkStart, currentChunkEnd] = calculateChunkRange(currentChunkCheck)
        const [nextChunkStart, nextChunkEnd] = [currentChunkEnd, currentChunkEnd + chunkDuration]

        setCurrentValue(map.get(secToFrame(currentTime / 1000, fps)))

        // Ensure current and next chunks are fetched
        const checkChunk = (start, end) => {
            const chunkKey = `${start}-${end}`
            if (chunkStatus.has(chunkKey)) return
            chunkStatus.set(chunkKey, true) // Mark as started downloading
            fetchChunkData(start, end)
        }
        checkChunk(currentChunkStart, currentChunkEnd)
        checkChunk(nextChunkStart, nextChunkEnd)
    }, [chunkStatus, assetTimeOffsetMs, fps])

    useEffect(() => {
        if (!path || !fvResponseParsers[path]) return

        onTimeUpdate()
        const interval = setInterval(onTimeUpdate, 150)
        return () => clearInterval(interval)
    }, [onTimeUpdate, path])

    if (!path) return undefined
    if (returnMap) return map
    if (path === "poi")
        return getInterpolatedFromMap(map, frameNumber, {x: 0.5, y: 0.5}, linear2D)
    if (path === "videoentities")
        return getInterpolatedFromMap(map, frameNumber, [])
    return getInterpolatedFromMap(map, frameNumber)
}