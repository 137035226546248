import "./VisualizePOI.css";
import React, {useCallback} from "react";
import {throttled} from "../../../utility/Utilities";
import { useVideoPlayer } from "../VideoPlayerProvider";
import { secToFrame } from "../../../utility/useFieldVisionData";
import Config from "../../../utility/Config";

function halfWidthOfRectangle (childAspectRatio, parentAspectRatio = 16 / 9) {
    if (typeof childAspectRatio === "string")
        childAspectRatio = childAspectRatio.split("/").reduce((num, den) => num / den)
    return childAspectRatio / (2 * parentAspectRatio)
}

export function VisualizePOI ({
    x, // Between 0 and 1
    y, // Between 0 and 1
    assetTimeOffsetMs=undefined,
    rectangle = true,
    cross = true,
    dimming = true,
    aspectRatio = "9/16",
    rectangleColor = "#9b9b9b",
    onDrag=undefined,
    onDragEnd=undefined,
}) {
    let rectangleEl = null
    if (rectangle) {
        const halfWidth = halfWidthOfRectangle(aspectRatio) * 100
        const left = Math.max(halfWidth, Math.min(100 - halfWidth, x * 100))
        rectangleEl = (
            <>
                <div className="poi-rectangle" style={{
                    aspectRatio: aspectRatio,
                    left: `${left}%`,
                    borderColor: rectangleColor,
                }}/>
                {dimming && (
                    <>
                        <div className="dimming-left" style={{width: `${left - halfWidth}%`}}/>
                        <div className="dimming-right" style={{width: `${100 - left - halfWidth}%`}}/>
                    </>
                )}
            </>
        )
    }
    let crossEl = null
    if (cross) {
        crossEl = (
            <div className="poi-cross" style={{
                left: `${x * 100}%`,
                top: `${y * 100}%`
            }}/>
        )
    }

    const {getCurrentTime} = useVideoPlayer()

    const onMouseDown = useCallback((e) => {
        if (typeof onDrag !== "function") return;

        e.preventDefault();
        const target = e.target;
        const boundingRect = target.getBoundingClientRect()

        let newX, newY, lastFrame

        // Interval for registering the latest frame, x, y
        const printInterval = setInterval(() => {
            let frame = secToFrame(getCurrentTime() + (assetTimeOffsetMs / 1000), Config.expectedFrameRate)
            // If there is a gap between the last frame and the current frame, register the missing frame
            if (!!frame && frame - lastFrame > 1) {
                // console.log("catch missing frame", frame - 1)
                onDrag(frame - 1, newX, newY)
            }
            onDrag(frame, newX, newY)
            // console.log("override", frame)
            lastFrame = frame
        }, 20)
        
        // Update the latest x and y when moving the mouse area/position
        const onMouseMove = throttled((e) => { 
            newX = Math.min(1, Math.max(0, (e.clientX - boundingRect.left) / boundingRect.width))
            newY = Math.min(1, Math.max(0, (e.clientY - boundingRect.top) / boundingRect.height))
        }, 20);
    
        const onMouseUp = () => {
            clearInterval(printInterval)
            onDragEnd()
            window.removeEventListener("mousemove", onMouseMove);
            window.removeEventListener("mouseup", onMouseUp);
        };
    
        onMouseMove(e);
        window.addEventListener("mousemove", onMouseMove);
        window.addEventListener("mouseup", onMouseUp);
    }, [onDrag]);

    if (typeof onDrag === "function") return (
        <div className="poi-rectangle-cont" onMouseDown={onMouseDown}>
            {rectangleEl}
            {crossEl}
        </div>
    )
    return (
        <>
            {rectangleEl}
            {crossEl}
        </>
    )
}